<template>
  <viewcard--c
    :title="
      $route.params.id > 0 ? 'Atualizar Relatório' : 'Cadastrar Relatório'
    "
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="$route.params.id > 0 ? btdelete : null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="confirmDelete"
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="reportsRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="URL *">
              <validation-provider
                #default="{ errors }"
                name="URL"
                rules="required"
              >
                <b-form-input
                  v-model="record.endpoint"
                  placeholder="https://enderecoaqui.com.br"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Chave Secreta *">
              <validation-provider
                #default="{ errors }"
                name="Chave"
                rules="required"
              >
                <div class="form-label-group">
                  <b-form-input
                    v-model="record.secret_key"
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="payload *">
              <validation-provider
                #default="{ errors }"
                name="Chave"
                rules="required"
              >
                <div class="form-label-group">
                  <b-form-textarea rows="7" v-model="record.payload2" />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Chave"
                rules="required"
              >
                <div class="form-label-group">
                  <b-form-input
                    v-model="record.ds_permission"
                    placeholder="Nome para o relatório"
                    autocomplete="off"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _iframeService from "@/services/Iframe-permission-service";
import _permissionsService from "@/services/group-permissions";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      btedit: { permission: `permission.reports.view` },
      btcreate: { permission: `permission.reports.view` },
      btdelete: { permission: `permission.reports.view` },
      loading: false,
      permissions: [],
      permissionSelected: null,
      record: {
        id: 0,
        endpoint: "",
        secret_key: "",
        ds_permission: "",
        payload2: "",
      },
    };
  },
  created() {
    localize("pt_BR", pt_br);
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    validationForm() {
      this.$refs.reportsRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _iframeService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      const payload = { data: { ...this.record } };

      const _createOrUpdate =
        this.record.id <= 0
          ? _iframeService.create(payload)
          : _iframeService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Relatório salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.loading = true;
      _iframeService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>