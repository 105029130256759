var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('viewcard--c',{attrs:{"title":_vm.$route.params.id > 0 ? 'Atualizar Relatório' : 'Cadastrar Relatório',"btsave":_vm.$route.params.id > 0 ? _vm.btedit : _vm.btcreate,"btdelete":_vm.$route.params.id > 0 ? _vm.btdelete : null,"btback":{},"busy":_vm.loading},on:{"clicked-save":_vm.validationForm,"clicked-delete":_vm.confirmDelete}},[_c('hr',{staticClass:"p-0 m-0 mb-1"}),_c('validation-observer',{ref:"reportsRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"URL *"}},[_c('validation-provider',{attrs:{"name":"URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"https://enderecoaqui.com.br","autocomplete":"off"},model:{value:(_vm.record.endpoint),callback:function ($$v) {_vm.$set(_vm.record, "endpoint", $$v)},expression:"record.endpoint"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Chave Secreta *"}},[_c('validation-provider',{attrs:{"name":"Chave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.record.secret_key),callback:function ($$v) {_vm.$set(_vm.record, "secret_key", $$v)},expression:"record.secret_key"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"payload *"}},[_c('validation-provider',{attrs:{"name":"Chave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-textarea',{attrs:{"rows":"7"},model:{value:(_vm.record.payload2),callback:function ($$v) {_vm.$set(_vm.record, "payload2", $$v)},expression:"record.payload2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome *"}},[_c('validation-provider',{attrs:{"name":"Chave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Nome para o relatório","autocomplete":"off"},model:{value:(_vm.record.ds_permission),callback:function ($$v) {_vm.$set(_vm.record, "ds_permission", $$v)},expression:"record.ds_permission"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }